
import { defineComponent, reactive, toRefs } from "vue";
import { useStore } from "vuex";
import { Store } from "@/store/";
import { useRoute } from "vue-router";

export default defineComponent({
  setup() {
    const route = useRoute();
    const store: Store = useStore();
    const { userid, username, dept_id } = store.state.app;


    const dataMap = reactive({
      dataList: [] as unknown as ActivityDataListReq,
      height: document.body.clientHeight,
      width: document.body.clientWidth,
      s: parseFloat(
        getComputedStyle(window.document.documentElement)["fontSize"]
      ),
    });

    const activityDataList = () => {
      store
        .dispatch("actCzActivityDataList", {
          userid: userid,
          username: username,
          dept_id: dept_id,
          option: route.query.option as string,
        })
        .then((res) => {
          if (res.status == 200) {
            if (res.data_list) {
              dataMap.dataList = res.data_list;
            }
          }
        })
        .catch((err) => {
          console.log(err);
        });
    };

    activityDataList();
    return {
      ...toRefs(dataMap),
    };
  },
});
